import "./index.scss";
import { Layout, message, Tooltip } from "antd";
// import MyHeader from "../../../components/Header";
import MyHeader from "../../../components/MyHeader";
import Scrollbars from "react-custom-scrollbars";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import PrivateRoute from "../../../components/PrivateRoute";
import MySidebar from "../components/MySidebar";
import routers from "../router/router";
import MySpin from "../../../components/MySpin";
import projectApi from "../../../apis/project/index";
import bondApi from "../../../apis/bond/index";
import { useDispatch, useSelector } from "react-redux";
import { setAdmins, setUserInfo } from "../../../store/user/user";

import UserApi from "../../../apis/user/index";
import { removeToken, setRedirectUrl, setToken } from "../../../unit/index";
import icon from "../../../assets/概览.svg";
// import OperationRecordContent from "../../../components/OperationRecordDrawer";
import { useUnmount } from "ahooks";
import { LeftOutlined } from "@ant-design/icons";
// import homeIcon from "../../../assets/尽调logo.png";
import homeIcon from "../../../assets/homeIcon.svg";
import {
  setBondProjectInfo,
  setBondMenusList,
} from "../../../store/bond/project";
import MenuLoadingPage from "../../../components/MenuLoadingPage";

const { Content } = Layout;

message.config({
  top: 10,
  duration: 3,
  maxCount: 1,
});

const BondHome = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  // const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [menuList, setMenuList] = useState([]);
  const [personalMenus, setPersonalMenus] = useState(null);
  const [licenseName, setLicenseName] = useState(null);

  const [loading, setLoading] = useState(true);
  const userInfo = useSelector((state) => state.User.userInfo) || {};
  const [userMenus, setUserMenus] = useState([]);

  const data = useSelector((state) => state.BondProject.bondProjectInfo);

  const [pageLoading, setPageLoading] = useState(true);

  const initMenu = (menuList) => {
    let notJurisdiction = false;
    menuList = menuList.map((item) => {
      if (item.function_name === "概览") {
        item.menu_url = `/bond/${id}/`;
      }
      if (item.children && item.children.length > 0) {
        item.children = item.children.map((_item) => {
          let route = routers.find(
            (r) =>
              r.menu_name === _item.function_name ||
              r.menu_code === _item.function_code
          );
          if(!_item.is_sup_func){
            route = null;
            _item.disable = true;
          }
          if (route) {
            _item.menu_url = `/bond/${id + route.menu_url}`;
          } else {
            _item.menu_url = `/bond/${id}/404`;
          }
          if (_item.menu_url === location.pathname && route) {
            notJurisdiction = _item.disable;
          }
          return _item;
        });
      }
      return item;
    });
    return {
      _menuList: menuList,
      notJurisdiction,
    };
  };

  const getAdminsFunc = async () => {
    projectApi.getAdmins().then((res) => {
      dispatch(setAdmins(res?.data || []));
    });
    //
  };

  const getUserMenus = () => {
    UserApi.getMenus().then((res) => {
      if (res.code === 200) {
        console.log(res?.data);
        const menusItem = res?.data?.find((_) => _.menu_code === "CDD_PROD");
        if (!menusItem) {
          // message.error('未匹配到本项目的目录数据!');
          const findItem = (res.data || [])?.find((i) => i.menu_url);
          //订阅过项目 跳到订阅的项目里
          if (findItem) {
            window.location.href = findItem.menu_url;
          } else {
            //没订阅过 跳到详情页
            window.location.href = localStorage.getItem("sub_url");
          }
          return;
        }
        setUserMenus(res?.data);
      } else {
        message.error(res.msg);
        setUserMenus([]);
      }
    });
  };

  useEffect(() => {
    const { _menuList, notJurisdiction } = initMenu(menuList);
    dispatch(setBondMenusList(JSON.parse(JSON.stringify(_menuList))));
    if (notJurisdiction) {
      navigate(`/bond/${id}/`);
    }
  }, [menuList]);

  const getUserInfo = async () => {
    let { data } = await UserApi.getUserInfo();
    if (!data) {
      logout();
      return;
    }
    dispatch(setUserInfo(data));
  };

  const logout = () => {
    let redirect = window.location.href //origin + "/login";
    UserApi.logout(redirect).then((res) => {
      // console.log(res.data.data.logout_url);
      removeToken();
      if (window.location.pathname !== "/login") {
        setRedirectUrl(window.location.href);
      }
      if (res?.data?.status === "redirect") {
        window.location.href = res.data.logout_url;
      }
      if (res.code == "3") {
        window.location.reload();
      }
    });
  };

  const getMenuTreeData = () => {
    return bondApi.getMenuTreeData("CDD_BOND").then((res) => {
      let data = [];
      if (res.code === 200) {
        data = res.data.data;
      }
      return data;
    });
  };

  //获取菜单
  const getMenuOfPersonal = () => {
    UserApi.getMenuOfPersonal().then((res) => {
      console.log(res);
      if (res.code === 200) {
        setPersonalMenus(res.data?.menu_list);
        setLicenseName(res.data?.license_name);
      } else {
        setPersonalMenus([]);
        setLicenseName(null);
        message.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (pageLoading) {
      return;
    }
    getUserInfo();
    getMenuOfPersonal();
    getAdminsFunc();
  }, [pageLoading]);

  useEffect(() => {
    if (id === undefined) {
      // navigate("/projectManagement");
      return;
    }
    //因为 projectProgressCode 项目进度会变化时会查询新的项目详情,所以这儿不用再次调
    getProjectInfo();
    getUserMenus();
    initMenuList();
    // console.log('--------Home--id-调取------',id);
  }, [id]);

  const initMenuList = async (list) => {
    let allMenu = await getMenuTreeData();
    allMenu.unshift({
      disable: false,
      id: 9999999,
      function_name: "概览",
      children: [],
      full_logo_url: icon,
    });
    setMenuList(allMenu);
  };

  useEffect(() => {
    document.title = `${
      data.issuer_name ? data.issuer_name : ""
    }债券后督项目详情-债券后督`;
  }, [data]);

  const getProjectInfo = () => {
    bondApi
      .getProjectInfo(id, {
        is_get_com_info: 1,
      })
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          setLoading(false);
          dispatch(setBondProjectInfo(res.data));
        } else {
          message.error(res.message);
          navigate("/bondManagement");
        }
      });
  };

  //切换企业成功回调
  const switchEnterpriseSuccessFunc = (data) => {
    console.log("switchEnterpriseSuccessFunc", data);
    setToken(data.token);
    navigate("/");
    window.location.reload();
  };

  const contactBusinessCallBack = () => {
    console.log("联系商务");
    if (localStorage.getItem("deploy") === "BOX") {
      return;
    }
    window.open(localStorage.getItem("sub_url"), "sub_url");
  };

  const menuClickCallBack = (item) => {
    if (item.menu_name === "帮助中心") {
      window.open(item.menu_url + localStorage.getItem("product_name"), item?.menu_name);
      return true;
    }
  };

  if (pageLoading) {
    return <MenuLoadingPage setPageLoading={setPageLoading}></MenuLoadingPage>;
  }

  if (loading) {
    return <MySpin></MySpin>;
  }

  return (
    <div className="bondHomeContent">
      <MyHeader
        logoDom={
          <span className="left-title-concent">
            <Tooltip overlayClassName="headerTipContent" title="回到首页">
              <div
                className="homeBox"
                onClick={() => {
                  navigate("/bondManagement");
                }}
              >
                <img src={homeIcon} alt="" className="homeIcon" />
                <div className="homeTip">债券后督</div>
              </div>
            </Tooltip>

            <div className="xie">/</div>
            <Tooltip
              overlayClassName="headerTipContent"
              title={
                <div className="headerTip">
                  <div className="headerTipTitle">
                    {data.issuer_name}债券后督项目
                  </div>
                  <div className="headerTipText">
                    尽调对象: {data.issuer_name}
                  </div>
                  <div className="headerTipText">尽调场景: 债券后督项目</div>
                </div>
              }
            >
              <div className="projectName">{data.issuer_name}债券后督项目</div>
            </Tooltip>
          </span>
        }
        userInfo={userInfo}
        userMenus={userMenus}
        personalMenus={personalMenus}
        logoutBack={logout}
        getUnreadNoticesFunc={projectApi.getUnreadNotices}
        getNoticesFunc={projectApi.getNotices}
        setReadNoticesFunc={projectApi.setReadNotices}
        deleteNoticesFunc={projectApi.deleteNotices}
        getUpdateLogList={projectApi.getUpdateLogList}
        getEnterpriseFunc={projectApi.getEnterprise}
        switchEnterpriseFunc={projectApi.switchEnterprise}
        switchEnterpriseCallBack={switchEnterpriseSuccessFunc}
        licenseName={licenseName}
        projectCode="CDD_PROD"
        getAuthTimeFunc={projectApi.getBondLicenseValid}
        getAdminsFunc={projectApi.getAdmins}
        contactBusinessCallBack={contactBusinessCallBack}
        menuClickCallBack={menuClickCallBack}
        editCompName={projectApi.editCompName}
        getLicenseFunc={projectApi.getLicense}
        // showNotice={false}
      ></MyHeader>

      <Layout className="my-layout">
        {/* <MyHeader
          title={data.project_name}
          company_name={data.company_name}
          scene_code_name={data.scene_code_name}
          showTip={true}
        ></MyHeader> */}

        <div
          className="contentBox"
          style={{
            height: `100%`,
          }}
        >
          <MySidebar></MySidebar>
          <Content className="my-content">
            {/* {element} */}
            <Scrollbars
              className="scrollbars"
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "100%", width: "100%" }}
            >
              <div className="contentBox">
                <Routes key={location.key}>
                  {routers.map((item) => (
                    <Route
                      path={item.menu_url}
                      key={item.menu_url}
                      element={<PrivateRoute>{item.element}</PrivateRoute>}
                    />
                  ))}
                </Routes>
                {/* <ProjectInfo></ProjectInfo> */}
              </div>
            </Scrollbars>
          </Content>
        </div>
        {/* <OperationRecordContent routers={routers}></OperationRecordContent> */}
      </Layout>
    </div>
  );
};
export default BondHome;
